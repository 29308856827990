import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpRequest, HttpParams, HttpHeaders } from "@angular/common/http";
import { Globals, Membership } from './global.constant';
import { Observable } from 'rxjs/internal/Observable';
@Injectable({
  providedIn: 'root',
})
export class HttpService {
  [x: string]: any;
  getCropss: any;
  addCrops: any;
  params = new HttpParams();
  getCrops() {
    throw new Error('Method not implemented.');
  }
  checkBank(value: any) {
    throw new Error('Method not implemented.');
  }
  checkbanks(value: any) {
    throw new Error('Method not implemented.');
  }
  constructor(private httpService: HttpClient) { }
  loginUser(data: any) {
    return this.httpService.post(Globals.route.login, data);
  }
  VerifyEmail(data: any) {
    return this.httpService.post(Globals.route['verify-otp'], data);
  }
  CheckEmail(email: any) {
    return this.httpService.post(Globals.route.checkemail, email);
  }
  ChangePassword(data: any, id: any) {
    return this.httpService.post(
      `${Globals.route['change-password']}/${id}`,
      data
    );
  }
  getCropType() {
    return this.httpService.get(Globals.route.cropType);
  }
  addCrop(formdata: FormData) {
    return this.httpService.post(Globals.route.addCrop, formdata);
  }
  getfarmervalue() {
    return this.httpService.get(Globals.route.getfarmervalue);
  }
  getgenderwisefarmer() {
    return this.httpService.get(Globals.route.genderwisefarmer);
  }
  orderPaymentCount() {
    return this.httpService.get(Globals.route.orderPaymentCount);
  }
  topFiveProduct() {
    return this.httpService.get(Globals.route.topFiveProduct);
  }
  salebymonth() {
    return this.httpService.get(Globals.route.salebymonth);
  }
  filterDataByStateMonthYear(state: any, month: any, year: any) {
    let query = new HttpParams();
    if (state) {
      query = query.append('state', state);
    }
    if (month) { query = query.append('month', month); }
    if (year) { query = query.append('year', year); }
    return this.httpService.get(Globals.route.filterDataByStateMonthYear, {
      params: query,
    });
  }
  landareabycrop() {
    return this.httpService.get(Globals.route.landareabycrop);
  }
  appdownload() {
    return this.httpService.get(Globals.route.appdownload);
  }
  userCount() {
    return this.httpService.get(Globals.route.userCount);
  }
  getCrop() {
    return this.httpService.get(Globals.route.getCrop);
  }
  getUser() {
    return this.httpService.get(Globals.route.getUser);
  }
  getUserInBatch() {
    return this.httpService.get(Globals.route.getUserInBatch);
  }
  getUserCount() {
    return this.httpService.get(Globals.route.getUserCount);
  }
  getBasicInfo() {
    return this.httpService.get(Globals.route.getBasicInfo);
  }
  gettotalInfo() {
    return this.httpService.get(Globals.route.gettotalInfo);
  }
  getIncomeInfo() {
    return this.httpService.get(Globals.route.getIncomeInfo);
  }
  getBankInfo() {
    return this.httpService.get(Globals.route.getBankInfo);
  }
  getCropInfo() {
    return this.httpService.get(Globals.route.getCropInfo);
  }
  getAgriInfo() {
    return this.httpService.get(Globals.route.agriInfoDetails);
  }
  getFutureCropInfo() {
    return this.httpService.get(Globals.route.getFutureCropInfo);
  }
  deleteCrop(id: any) {
    return this.httpService.delete(`${Globals.route.deleteCrop}/${id}`);
  }
  updateCrop(id: any, formData: any) {
    return this.httpService.put(`${Globals.route.updateCrop}/${id}`, formData);
  }
  getCropByID(id: any) {
    return this.httpService.get(`${Globals.route.getCropById}/${id}`);
  }
  getProfileCount() {
    return this.httpService.get(Globals.route.getProfileCount);
  }
  // getappCount() {
  //   return this.httpService.get(Globals.route.getappCount);
  // }
  addimportcase(formdata: FormData) {
    return this.httpService.post(Globals.route.addimportcase, formdata);
  }
  addVariety(formdata: FormData) {
    return this.httpService.post(Globals.route.addVariety, formdata);
  }
  getVariety() {
    return this.httpService.get(Globals.route.getVariety);
  }
  todaybreakup() {
    return this.httpService.get(Globals.route.todaybreakup);
  }
  orderbreakup() {
    return this.httpService.get(Globals.route.orderbreakup);
  }
  paymentbreakup() {
    return this.httpService.get(Globals.route.paymentbreakup);
  }
  getVarietybyid(id: any) {
    return this.httpService.get(`${Globals.route.getVarietybyid}/${id}`);
  }
  getqualityparameterbycropid(id: any) {
    return this.httpService.get(`${Globals.route.getqualityparameterbycropid}/${id}`);
  }
  getAppointmentByFarmerID(id: any) {
    return this.httpService.get(`${Globals.route.getAppointmentByFarmerID}/${id}`);
  }
  updateAppointmentStatus(id: any, formData: any) {
    return this.httpService.patch(`${Globals.route.updateappointmentstatus}/${id}`, formData);
  }
  getVarietyByLanguage(language: any) {
    return this.httpService.get(
      `${Globals.route.getVarietyByLanguage}/${language}`
    );
  }
  updateVariety(id: any, formData: any) {
    return this.httpService.put(
      `${Globals.route.updateVariety}/${id}`,
      formData
    );
  }
  deleteVariety(id: any) {
    return this.httpService.delete(`${Globals.route.deleteVariety}/${id}`);
  }
  updatebyproductcode(formData: any) {
    return this.httpService.put(
      Globals.route.updatebyproductcode,
      formData
    );
  }
  updatestock(formData: any) {
    return this.httpService.post(
      Globals.route.updateInventory,
      formData
    );
  }
  updatestockImages(file: any) {
    const formData: FormData = new FormData();
    formData.append('file', file);
    const request = new HttpRequest('POST', Globals.route.updateInventoryImages, formData, {
      reportProgress: false,
      responseType: 'text',
    });
    return this.httpService.request(request);
  }
  // public importDataFromCSV(csvText: string): Array<any> {
  //   const propertyNames = csvText.slice(0, csvText.indexOf('\n')).split(',');
  //   const dataRows = csvText.slice(csvText.indexOf('\n') + 1).split('\n');
  //   let dataArray: any[] = [];
  //   dataRows.forEach((row) => {
  //     let values = row.split(',');
  //     let obj: any = new Object();
  //     for (let index = 0; index < propertyNames.length; index++) {
  //       const propertyName: string = propertyNames[index];
  //       let val: any = values[index];
  //       if (val === '') {
  //         val = null;
  //       }
  //       obj[propertyName] = val;
  //     }
  //     dataArray.push(obj);
  //   });
  //   return dataArray;
  // }
  addcroptype(formdata: FormData) {
    return this.httpService.post(Globals.route.addcroptype, formdata);
  }
  getcroptype() {
    return this.httpService.get(Globals.route.getcroptype);
  }
  //  getCropTypeByLanguage(id:any){
  //   return this.httpService.get(Globals.route.getCropTypeByLanguage,formdata)
  //  }
  getCropTypeByLanguage(language: any) {
    return this.httpService.get(
      `${Globals.route.getCropTypeByLanguage}/${language}`
    );
  }
  getCroptypebyid(id: any) {
    return this.httpService.get(`${Globals.route.getCroptypebyid}/${id}`);
  }
  deleteCropType(id: any) {
    return this.httpService.delete(`${Globals.route.deleteCropType}/${id}`);
  }
  updateCropType(id: any, formData: FormData) {
    return this.httpService.put(
      `${Globals.route.updateCropType}/${id}`,
      formData
    );
  }
  getTehsil() {
    return this.httpService.get(Globals.route.tehsil);
  }
  addTehsil(data: any) {
    return this.httpService.post(Globals.route.tehsil, data);
  }
  updateTehsil(id: string, data: any) {
    return this.httpService.put(Globals.route.tehsil + "/updatetehsil/" + id, data);
  }
  //ADD COUNTRY API
  addCountry(formdata: FormData) {
    return this.httpService.post(Globals.route.addCountry, formdata);
  }
  getCountry() {
    return this.httpService.get(Globals.route.getCountry);
  }
  checkCountry(formdata: any) {
    return this.httpService.post(Globals.route.checkCountry, formdata);
  }
  updateCountry(id: any, formData: any) {
    return this.httpService.put(
      `${Globals.route.updateCountry}/${id}`,
      formData
    );
  }
  getCountrybyid(id: any) {
    return this.httpService.get(`${Globals.route.getCountrybyid}/${id}`);
  }
  deleteCountry(id: any) {
    return this.httpService.delete(`${Globals.route.deleteCountry}/${id}`);
  }
  //state service
  //ADD state API
  addState(formdata: FormData) {
    return this.httpService.post(Globals.route.addState, formdata);
  }
  getState() {
    return this.httpService.get(Globals.route.getState);
  }
  updateState(id: any, formData: any) {
    return this.httpService.put(`${Globals.route.updateState}/${id}`, formData);
  }
  getStatebyid(id: any) {
    return this.httpService.get(`${Globals.route.getStatebyid}/${id}`);
  }
  deleteState(id: any) {
    return this.httpService.delete(`${Globals.route.deleteState}/${id}`);
  }
  checkStateCode(stateCode: any) {
    let qurey = new HttpParams();
    qurey = qurey.append('stateCode', stateCode);
    return this.httpService.get(Globals.route.checkStateCode, {
      params: qurey,
    });
  }
  //
  checkState(formdata: any) {
    return this.httpService.post(Globals.route.checkState, formdata);
  }
  getStateByCountry(formData: any) {
    return this.httpService.post(Globals.route.getStateByCountry, formData);
  }
  //District
  addDistrict(formdata: FormData) {

    return this.httpService.post(Globals.route.addDistrict, formdata);
  }
  getDistrict() {
    return this.httpService.get(Globals.route.getDistrict);
  }
  getKisanMitraWiseFarmer(kisanMitraName: any) {
    let query = new HttpParams();
    query = query.append('kisanMitraName', kisanMitraName);
    return this.httpService.get(Globals.route.kisanmitrawisefarmer, { params: query });
    // return this.httpService.get(Globals.route.districtwisefarmer);
  }
  getDistrictWiseFarmer(state: any) {
    let query = new HttpParams();
    query = query.append('state', state);
    return this.httpService.get(Globals.route.districtwisefarmer, { params: query });
    // return this.httpService.get(Globals.route.districtwisefarmer);
  }
  getVillageWiseFarmer(state: any, district: any) {
    let query = new HttpParams();
    query = query.append('state', state);
    query = query.append('district', district);
    return this.httpService.get(Globals.route.villagewisefarmer, { params: query });
    // return this.httpService.get(Globals.route.villagewisefarmer);
  }
  updateDistrict(id: any, formData: any) {
    return this.httpService.patch(
      `${Globals.route.updateDistrict}/${id}`,
      formData
    );
  }
  getDistrictbyid(id: any) {
    return this.httpService.get(`${Globals.route.getDistrictbyid}/${id}`);
  }
  deleteDistrict(id: any) {
    return this.httpService.delete(`${Globals.route.deleteDistrict}/${id}`);
  }
  //
  checkDistrict(district: any) {
    let query = new HttpParams();
    query = query.append('district', district);
    return this.httpService.get(Globals.route.checkDistrict, { params: query });
  }
  getDistrictbystate(stateName: any) {
    let query = new HttpParams();
    query = query.append('stateName', stateName);
    return this.httpService.get(Globals.route.getDistrictbystate, {
      params: query,
    });
  }
  checkDistrictCode(districtCode: any) {
    let query = new HttpParams();
    query = query.append('districtCode', districtCode);
    return this.httpService.get(Globals.route.checkDistrictCode, {
      params: query,
    });
  }
  //block
  getBlock() {
    return this.httpService.get(Globals.route.block);
  }
  addBlock(formData: FormData) {
    return this.httpService.post(Globals.route.block, formData);
  }
  updateBlock(id: any, formData: any) {
    return this.httpService.patch(`${Globals.route.block}/${id}`, formData);
  }
  deleteBlock(id: any) {
    return this.httpService.delete(`${Globals.route.block}/${id}`);
  }
  getBlockByDistrict(district: any) {
    let query = new HttpParams();
    query = query.append('district', district);
    return this.httpService.get(Globals.route.blockbyDistrict, {
      params: query,
    });
  }
  checkBlockName(block: any, district: any, stateName) {
    let query = new HttpParams();
    query = query.append('block', block);
    query = query.append('district', district);
    query = query.append('stateName', stateName);
    return this.httpService.get(Globals.route.checkBlockName, {
      params: query,
    });
  }
  checkBlockCode(blockCode: any) {
    let query = new HttpParams();
    query = query.append('blockCode', blockCode);
    return this.httpService.get(Globals.route.checkBlockCode, {
      params: query,
    });
  }
  //village
  addVillage(formData: any) {
    return this.httpService.post(Globals.route.addVillage, formData);
  }
  getVillage() {
    return this.httpService.get(Globals.route.getVillage);
  }
  updateVillage(id: any, formData: any) {
    return this.httpService.put(
      `${Globals.route.updateVillage}/${id}`,
      formData
    );
  }
  checkVillage(village: any, block: any, district: any, stateName) {
    let query = new HttpParams();
    query = query.append('village', village);
    query = query.append('block', block);
    query = query.append('district', district);
    query = query.append('stateName', stateName);
    return this.httpService.get(Globals.route.checkVillage, {
      params: query,
    });
  }
  checkMCode(kmCode: any) {
    let query = new HttpParams();
    query = query.append('kmCode', kmCode);
    return this.httpService.get(Globals.route.checkMCode, {
      params: query,
    });
  }
  deleteVillage(id: any) {
    return this.httpService.delete(`${Globals.route.deleteVillage}/${id}`);
  }
  getVillageByBlock(id: any) {
    return this.httpService.get(`${Globals.route.getVillageByBlock}/${id}`);
  }
  getvillageByCity(formData: any) {
    return this.httpService.post(Globals.route.getVillageByCity, formData);
  }
  //distributor section
  addDistributor(formdata: FormData) {
    return this.httpService.post(Globals.route.addDistributor, formdata);
  }
  getDistributor() {
    return this.httpService.get(Globals.route.getDistributor);
  }
  updateDistributor(id: any, formData: any) {
    return this.httpService.put(
      `${Globals.route.updateDistributor}/${id}`,
      formData
    );
  }
  deleteDistributor(id: any) {
    return this.httpService.delete(`${Globals.route.deleteDistributor}/${id}`);
  }
  //Mandi section
  addMandi(formdata: FormData) {
    return this.httpService.post(Globals.route.addMandi, formdata);
  }
  getMandi() {
    return this.httpService.get(Globals.route.getMandi);
  }
  updateMandi(id: any, formData: any) {
    return this.httpService.put(`${Globals.route.updateMandi}/${id}`, formData);
  }
  deleteMandi(id: any) {
    return this.httpService.delete(`${Globals.route.deleteMandi}/${id}`);
  }
  getMandiByLanguage(language: any) {
    return this.httpService.get(
      `${Globals.route.getMandiByLanguage}/${language}`
    );
  }
  getMandiss(id: any) {
    return this.httpService.get(`${Globals.route.getMandiss}/${id}`);
  }
  //unit Section
  addUnit(formdata: FormData) {
    return this.httpService.post(Globals.route.addUnit, formdata);
  }
  getUnit() {
    return this.httpService.get(Globals.route.getUnit);
  }
  updateUnit(id: any, formData: any) {
    return this.httpService.put(`${Globals.route.updateUnit}/${id}`, formData);
  }
  deleteUnit(id: any) {
    return this.httpService.delete(`${Globals.route.deleteUnit}/${id}`);
  }
  getUnitByid(id: any) {
    return this.httpService.get(`${Globals.route.getUnitByid}/${id}`);
  }
  //Membership Section
  addMembership(formdata: FormData) {
    return this.httpService.post(Globals.route.addMembership, formdata);
  }
  getMembership() {
    return this.httpService.get(Globals.route.getMembership);
  }
  updateMembership(id: any, formData: any) {
    return this.httpService.put(
      `${Globals.route.updateMembership}/${id}`,
      formData
    );
  }
  deleteMembership(id: any) {
    return this.httpService.delete(`${Globals.route.deleteMembership}/${id}`);
  }
  getMembershipByid(id: any) {
    return this.httpService.get(`${Globals.route.getUnitByid}/${id}`);
  }
  //irrigation section
  //Membership Section
  addIrrigation(formdata: FormData) {
    return this.httpService.post(Globals.route.addIrrigation, formdata);
  }
  getaddIrrigation() {
    return this.httpService.get(Globals.route.getaddIrrigation);
  }
  updateaddIrrigation(id: any, formData: any) {
    return this.httpService.put(
      `${Globals.route.updateaddIrrigation}/${id}`,
      formData
    );
  }
  deleteaddIrrigation(id: any) {
    return this.httpService.delete(
      `${Globals.route.deleteaddIrrigation}/${id}`
    );
  }
  getIrrigationByid(id: any) {
    return this.httpService.get(`${Globals.route.getIrrigationByid}/${id}`);
  }
  //bank Section
  addBank(formdata: FormData) {
    return this.httpService.post(Globals.route.addBank, formdata);
  }
  getBank() {
    return this.httpService.get(Globals.route.getBank);
  }
  updateBank(id: any, formData: any) {
    return this.httpService.put(`${Globals.route.updateBank}/${id}`, formData);
  }
  deleteBank(id: any) {
    return this.httpService.delete(`${Globals.route.deleteBank}/${id}`);
  }
  getBankByid(id: any) {
    return this.httpService.get(`${Globals.route.getBankByid}/${id}`);
  }
  getBankByIfsc(id: any) {
    return this.httpService.get(`${Globals.route.getBankByIfsc}/${id}`);
  }
  ///Farmer Profile
  getFarmerAll() {
    return this.httpService.get(Globals.route.getfamer);
  }
  //getFormaerInBatch
  getFarmerDataInBatch() {
    return this.httpService.get(Globals.route.getfarmerbatch);
  }
  getFarmerById(id: any) {
    return this.httpService.get(`${Globals.route.getFarmerById}/${id}`);
  }
  //livestock profile
  addLivestock(formdata: FormData) {
    return this.httpService.post(Globals.route.addLivestock, formdata);
  }
  getLivestocks() {
    return this.httpService.get(Globals.route.getLivestocks);
  }
  updateLivestock(id: any, formData: any) {
    return this.httpService.put(
      `${Globals.route.updateLivestock}/${id}`,
      formData
    );
  }
  deleteLivestock(id: any) {
    return this.httpService.delete(`${Globals.route.deleteLivestock}/${id}`);
  }
  getLivestockByid(id: any) {
    return this.httpService.get(`${Globals.route.getLivestockByid}/${id}`);
  }
  //Appointment
  getAppointment() {
    return this.httpService.get(Globals.route.getAppointment);
  }
  getAppointmentByID(id: any) {
    return this.httpService.get(`${Globals.route.getAppointmentById}/${id}`);
  }
  getAppointmentByID1(id: any) {
    return this.httpService.get(`${Globals.route.getAppointmentById}/${id}`);
  }
  //Mandimaster profile
  addMandimaster(formdata: FormData) {
    return this.httpService.post(Globals.route.addMandimaster, formdata);
  }
  getMandimaster() {
    return this.httpService.get(Globals.route.getMandimaster);
  }
  updateMandimaster(id: any, formData: any) {
    return this.httpService.put(
      `${Globals.route.updateMandimaster}/${id}`,
      formData
    );
  }
  deleteMandimaster(id: any) {
    return this.httpService.delete(`${Globals.route.deleteMandimaster}/${id}`);
  }
  getMandimasterss(id: any) {
    return this.httpService.get(`${Globals.route.getMandimasterss}/${id}`);
  }
  //language
  addLanguage(formdata: FormData) {
    return this.httpService.post(Globals.route.language, formdata);
  }
  getLanguage() {
    return this.httpService.get(Globals.route.language);
  }
  updateLanguage(id: any, formData: any) {
    return this.httpService.put(`${Globals.route.language}/${id}`, formData);
  }
  deleteLanguage(id: any) {
    return this.httpService.delete(`${Globals.route.language}/${id}`);
  }
  languageByID(id: any) {
    return this.httpService.get(`${Globals.route.languageByID}/${id}`);
  }
  checkLanguage(language: any) {
    return this.httpService.get(`${Globals.route.checkLanguage}/${language}`);
  }
  checkMobile(mobile: any) {
    return this.httpService.post(Globals.route.checkMobile, mobile);
  }
  checkEmail(email: any) {
    return this.httpService.post(Globals.route.checkEmail, email);
  }
  getDisrictByPincode(pinCode: any) {
    return this.httpService.get(
      `${Globals.route.getDisrictByPincode}/${pinCode}`
    );
  }
  getAppointmentByCount() {
    return this.httpService.get(Globals.route.getAppointmentByCount);
  }
  //getbrand
  getbrands() {
    return this.httpService.get(Globals.route.getbrands);
  }
  //center Manager Related Api Start
  getQualityParameter() {
    return this.httpService.get(Globals.route.getqualityparameter);
  }
  addQualityParameter(formdata: any) {
    return this.httpService.post(Globals.route.addqualityparameter, formdata);
  }
  getqualityparameterbyid(id: any) {
    return this.httpService.get(`${Globals.route.getqualityparameterbyid}/${id}`);
  }
  updateQualityParameter(id: any, formData: any) {
    // return this.httpService.post(Globals.route.updateKissanMitra,formdata)
    return this.httpService.put(
      `${Globals.route.updatequalityparameter}/${id}`,
      formData
    );
  }
  deleteQualityParameter(id: any) {
    return this.httpService.delete(`${Globals.route.deletequalityparameter}/${id}`);
  }
  deleteCenter(id) {
    return this.httpService.delete(`${Globals.route.deletecenter}/${id}`);
  }
  getCenterById(id) {
    return this.httpService.get(`${Globals.route.getcenterbyid}/${id}`);
  }
  updateCenter(id: any, formData: any) {
    return this.httpService.put(
      `${Globals.route.updatecenter}/${id}`,
      formData
    );
  }
  getRolesPermissionById(id: any) {
    return this.httpService.get(`${Globals.route.getroles_permissionbyid}/${id}`);
  }
  //center Manager Related Api end
  //kissnmitra
  addKissanMitra(formdata: any) {
    return this.httpService.post(Globals.route.addKissanMitra, formdata);
  }
  addkissanmtrainmapping(formdata: any) {
    return this.httpService.post(
      Globals.route.addkissanmtrainmapping,
      formdata
    );
  }
  addUser(formdata: any) {
    return this.httpService.post(
      Globals.route.addUser,
      formdata
    );
  }
  addcenter(formdata: any) {
    return this.httpService.post(
      Globals.route.addcenter,
      formdata
    );
  }
  addCropQuatity(formdata: any) {
    return this.httpService.post(
      Globals.route.addcropsale,
      formdata
    );
  }
  getstaticparameter() {
    return this.httpService.get(Globals.route.getstaticparameter);
  }
  getcenter() {
    return this.httpService.get(Globals.route.getcenter);
  }
  getAllCenterManager() {
    return this.httpService.get(Globals.route.getAllCenterManager);
  }
  // updateRolesAndPermission(id: any) {
  //   return this.httpService.get(`${Globals.route.getroles_permissionbyid}/${id}`);
  // }
  updateRolesAndPermission(id: any, formData: any) {
    return this.httpService.put(
      `${Globals.route.updateroles_permission}/${id}`,
      formData
    );
  }
  deleteRolesAndPermission(id: any) {
    return this.httpService.delete(`${Globals.route.deleteroles_permission}/${id}`);
  }
  addRolesAndPermissions(formdata: any) {
    return this.httpService.post(
      Globals.route.addRolesAndPermissions,
      formdata
    );
  }
  getRolesAndPermissions() {
    return this.httpService.get(Globals.route.getRolesAndPermissions);
  }
  getKissanMitra() {
    return this.httpService.get(Globals.route.getKissanMitra);
  }
  getkissanmitamapingdata() {
    return this.httpService.get(Globals.route.getkissanmitamapingdata);
  }
  updateKissanMitra(id: any, formData: any) {
    // return this.httpService.post(Globals.route.updateKissanMitra,formdata)
    return this.httpService.post(
      `${Globals.route.updateKissanMitra}/${id}`,
      formData
    );
  }
  udpateKissanmitrawithid(id: any, formData: any) {
    return this.httpService.put(
      `${Globals.route.udpateKissanmitrawithid}/${id}`,
      formData
    );
  }
  updatekisanmitramapping(id: any, formData: any) {
    return this.httpService.patch(
      `${Globals.route.updatekisanmitramapping}/${id}`,
      formData
    );
  }
  deleteKissanMitra(id: any) {
    return this.httpService.delete(`${Globals.route.deleteKissanMitra}/${id}`);
  }
  deletekisanmitramap(id: any) {
    return this.httpService.delete(
      `${Globals.route.deletekisanmitramap}/${id}`
    );
  }
  checkemployeeid(id: any) {
    return this.httpService.get(`${Globals.route.checkemployeeid}/${id}`);
  }
  // mapKissanMitra(formdata:FormData){
  //   return this.httpService.post(Globals.route.mapKissanMitra,formdata)
  //  }
  mapKissanMitra(formData: any) {
    return this.httpService.post(Globals.route.mapKissanMitra, formData);
  }
  getkisanmitravalue() {
    return this.httpService.get(Globals.route.getkisanmitravalue);
  }
  getkisanmitrabyvillage(formData: any) {
    return this.httpService.post(
      Globals.route.getkisanmitrabyvillage,
      formData
    );
  }
  getstateanddistrict(formData: any) {
    return this.httpService.post(Globals.route.getstateanddistrict, formData);
  }
  getvillageandrefrelcode() {
    return this.httpService.get(Globals.route.getvillageandrefrelcode);
  }
  listFarmerData() {
    return this.httpService.get(Globals.route.sowingcropresponse);
  }
  getorder() {
    return this.httpService.get(Globals.route.getorder);
  }
  getorderByDate(date: string) {
    return this.httpService.post(Globals.route.getorder, {date: date});
  }
  getorderMeta(date: string) {
    return this.httpService.post(Globals.route.orderCounts, {date: date});
  }
  getorderCSV() {
    return this.httpService.get(Globals.route.orderCSV);
  }
  getdayorderCSV(date: string) {
    return this.httpService.post(Globals.route.orderCSV, { date: date });
  }
  getorderYearly(year: number) {
    return this.httpService.get(Globals.route.yearlyOrders + "/" + year);
  }
  // getorderByID(id: any) {
  //   return this.httpService.get(`${Globals.route.getorder}/${id}`)
  // }
  orderbyid(id: any) {
    return this.httpService.get(`${Globals.route.orderbyid}/${id}`);
  }
  updateOrder(id: any, formdata: any) {
    return this.httpService.patch(`${Globals.route.updateOrder}/${id}`, formdata);
  }
  cancelTheOrder(id: any, cancellationData: any) {
    return this.httpService.patch(`${Globals.route.cancelTheOrder}/${id}`, cancellationData);
  }
  getTaxById(id: any) {
    return this.httpService.get(`${Globals.route.getTaxById}/${id}`);
  }
  Addpayment(formdata: any) {
    return this.httpService.post(Globals.route.payment, formdata);
  }
  Getpaymentbyid(id: any) {
    return this.httpService.get(`${Globals.route.payment}/${id}`);
  }
  paymentbyorder(id: any) {
    return this.httpService.get(`${Globals.route.paymentbyorder}/${id}`);
  }
  deletePaymentbyorder(id: any) {
    return this.httpService.delete(`${Globals.route.payment}/${id}`);
  }
  checkAmount(paymentamount: any) {
    return this.httpService.get(
      `${Globals.route.checkpayment}/${paymentamount}`,
      { params: this.params }
    );
  }
  AddDelivery(formdata: any) {
    return this.httpService.post(Globals.route.delivery, formdata);
  }
  getDelivery() {
    return this.httpService.get(Globals.route.delivery);
  }
  getDeliveryByOrder(id: any) {
    return this.httpService.get(`${Globals.route.delivery}/${id}`);
  }
  getDeliveryByOrderId(id: any) {
    return this.httpService.get(`${Globals.route.deliverybyorder}/${id}`);
  }
  updateDelivery(id: any, formdata: any) {
    return this.httpService.patch(
      `${Globals.route.updatedelivery}/${id}`,
      formdata
    );
  }
  upload(file: File): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    const request = new HttpRequest('POST', Globals.route.upload, formData, {
      reportProgress: false,
      responseType: 'text',
    });
    return this.httpService.request(request);
  }
  surveyreport(data: any) {
    return this.httpService.post(Globals.route.survey_responses, data);
  }
  maizereport(data: any) {
    return this.httpService.post(Globals.route.maize_responses, data);
  }
  monthlyExport(data: any) {
    return this.httpService.post(Globals.route.monthlyExport, data);
  }
  getDeliveryPersons() {
    return this.httpService.get(Globals.route.deliveryPerson);
  }
  getActiveDeliveryPersons() {
    return this.httpService.get(Globals.route.activeDeliveryPerson);
  }
  addDeliveryPerson(data: any) {
    return this.httpService.post(Globals.route.deliveryPerson, data);
  }
  updateDeliveryPerson(id: string, data: any) {
    return this.httpService.put(Globals.route.deliveryPerson + "/" + id, data);
  }
  deleteDeliveryPerson(id: string) {
    return this.httpService.delete(Globals.route.deliveryPerson + "/" + id);
  }
  activeDeliveryPerson(id: string, active: boolean) {
    return this.httpService.patch(Globals.route.deliveryPerson + "/" + id + "/" + active, {});
  }
  //quick village
  villageForQuick() {
    return this.httpService.get(Globals.route.villageForQuick);
  }
  villages() {
    return this.httpService.get(Globals.route.villages);
  }
  quickVillage() {
    return this.httpService.get(Globals.route.quickVill);
  }
  saveQuickVillage(vil: any[]) {
    return this.httpService.post(Globals.route.saveQuickVill, { villages: vil });
  }
  toggleQuickVillage(id: string) {
    return this.httpService.patch(Globals.route.quickVill + "/" + id, {});
  }

  addNewMembership(data: any) {
    return this.httpService.post(Membership.route.addmembership, data);
  }
  getAllMembership() {
    return this.httpService.get(Membership.route.membership);
  }
  getAllMembers() {
    return this.httpService.get(Membership.route.members);
  }
  getMembershipById(id: any) {
    return this.httpService.get(Membership.route.membership + '/' + id);
  }
  lastMembershipId() {
    return this.httpService.get(Membership.route.membership + '/latestId');
  }
  lastDarkStoreId() {
    return this.httpService.get(Globals.route.darkstore + '/latestId');
  }
  darkStore() {
    return this.httpService.get(Globals.route.darkstore);
  }
  addDarkStore(data: any) {
    return this.httpService.post(Globals.route.darkstore, data);
  }
  getDarkStore(id: String) {
    return this.httpService.get(Globals.route.darkstore + '/' + id);
  }
  darkStoreStock(id: String) {
    return this.httpService.get(Globals.route.darkstore + '-stock/' + id);
  }
  editDarkStore(data: any, id: String) {
    return this.httpService.put(Globals.route.darkstore + '/' + id, data);
  }
  lastWarehouseId() {
    return this.httpService.get(Globals.route.warehouse + '/latestId');
  }
  activeMembership(id: any) {
    return this.httpService.patch(Membership.route.membership + '/' + id + '/activate', {});
  }
  updateMembershipById(id: any, data: any) {
    return this.httpService.patch(Membership.route.membership + '/' + id, data);
  }
  deleteMembershipById(id: any) {
    return this.httpService.delete(Membership.route.membership + '/' + id);
  }
  villageKMs(villages: String[]) {
    return this.httpService.post(Globals.route.villageKms, villages);
  }
}
