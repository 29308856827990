<div id="cover-spin"></div>
<div class="app app-header-fixed ">
   <!-- header -->
   <header id="header" class="app-header navbar" role="menu">
      <!-- navbar header -->
      <div class="navbar-header bg-light lter">
         <button class="pull-right visible-xs dk" ui-toggle-class="show" target=".navbar-collapse">
            <i class="glyphicon glyphicon-cog"></i>
         </button>
         <button class="pull-right visible-xs" ui-toggle-class="off-screen" target=".app-aside" ui-scroll="app">
            <i class="glyphicon glyphicon-align-justify"></i>
         </button>
         <!-- brand -->
         <span class="navbar-brand text-lt ">
            <!-- <i class="fa fa-btc"></i> -->
            <!-- <img src="/img/bigleap.png" alt="." class="hide"> -->
            <span class="visible-folded hidden-xs"><img src="../../../assets/img/main-logo-h.png" style=""
                  alt="bigleap"></span>
            <span class="hidden-folded">
               <img src="../../../assets/img/main-logo-h.png" alt="lgoo">
               <span style="font-size:2.2rem; margin-left: 2rem; color: #8bc34a;">BioVeda</span>
            </span>
         </span>
         <!-- / brand -->
      </div>
      <!-- / navbar header -->
      <!-- navbar collapse -->
      <div class="collapse pos-rlt navbar-collapse box-shadow bg-white-only">
         <!-- buttons -->
         <div class="nav navbar-nav hidden-xs">
            <a href="#" class="btn no-shadow navbar-btn g-px-5" ui-toggle-class="app-aside-folded" target=".app">
               <i class="fa fa-dedent fa-fw text"></i>
               <i class="fa fa-indent fa-fw text-active"></i>
            </a>
            <a class="btn no-shadow navbar-btn g-px-0 m-t-10">
               <i class="fa fa-circle text-success text-10 g-px-5"></i> <small class=""><span
                     id="date_header_time"></span><span id="clock"></span></small>
            </a>
         </div>
         <!-- / buttons -->
         <!-- nabar right -->
         <ul class="nav navbar-nav navbar-right">
            <li class="dropdown">
               <a href="/" class="dropdown-toggle">
                  <i class="fa fa-fw fa-plus visible-xs-inline-block"></i>
                  <!-- <i class="fa fa-car text-xs text-info g-px-5"></i> Dashboard -->
               </a>
            </li>
            <!--  <li class="dropdown">
            <a href="#" data-toggle="dropdown" class="dropdown-toggle">
            <i class="fa fa-fw fa-plus visible-xs-inline-block"></i><i class="fa fa-file-text text-xs text-info g-px-5"></i>Report </a>
         </li> -->
            <!-- <li class="dropdown">
            <a href="#" data-toggle="dropdown" class="dropdown-toggle get_last_10_notification" data-toggle="dropdown">
            <i class="fa fa-bell fa-fw g-px-5"></i>
            <span class="visible-xs-inline">Notifications</span>
            <span class="badge badge-sm up bg-info pull-right-xs"><span class="noti_count_show">0</span></span>
            </a>
            <div class="dropdown-menu w-600">
               <div class="panel bg-white">
                  <div class="panel-heading b-light bg-light">
                     <a href="/vehicle/notification_all">See all the notifications</a>
                  </div>
                  <div class="list-group noti_content_show">
                  </div>
               </div>
            </div>
         </li> -->
            <li class="dropdown">
               <a href="#" data-toggle="dropdown" class="dropdown-toggle clear" data-toggle="dropdown">
                  <!-- <span class="thumb-sm avatar m-t-n-sm m-b-n-sm m-r-sm">
                  <img src="../../assets/img/a0.jpg" alt="...">
                  <i class="on md b-white bottom"></i>
                  </span> -->
                  <span class="hidden-sm hidden-md">Hi <span
                        class="text-uppercase user-profile-name">{{this.name}}</span></span> <b class="caret"></b>
               </a>
               <!-- dropdown -->
               <ul class="dropdown-menu animated fadeInRight w">
                  <li>
                     <!-- <a href>
                    <span class="badge bg-danger pull-right">30%</span>
                    <span>Settings</span>
                  </a> -->
                  </li>
                  <!-- <li>
                  <a ui-sref="app.page.profile" href="/admin-profile" class="user-update-profile">Profile</a>
               </li> -->
                  <li>
                     <a data-toggle="modal" data-target="#user-profile" aria-hidden="true" ui-sref="app.page.profile"
                        class="user-change-password">Change Password</a>
                  </li>
                  <li>
                     <!-- <a ui-sref="app.docs">
                  <span class="label bg-info pull-right">new</span>
                  Help
                  </a> -->
                  </li>
                  <li class="divider"></li>
                  <li>
                     <a (click)="logout()">Logout</a>
                  </li>
               </ul>
               <!-- / dropdown -->
            </li>
         </ul>
         <!-- / navbar right -->
      </div>
      <!-- / navbar collapse -->
   </header>
   <!-- / header -->
   <!------------------------------------------------- User Profile model ------------------------------->
   <div class="modal fade" id="user-profile" role="dialog">
      <div class="modal-dialog r-2x">
         <div class="modal-content r-2x b-b-3x b-b-theme">
            <div class="modal-header bg-info">
               <button type="button" class="close" data-dismiss="modal"><i
                     class="fa fa-times text-black m-t-xs"></i></button>
               <h4 class="modal-title text-white font-bold">Change Password</h4>
            </div>
            <div class="modal-body">
               <form [formGroup]="appForm" (ngSubmit)="changeSubmit()">
                  <div class="row">
                     <div class="col-md-12">
                        <div class="form-group">
                           <label class="control-label font-weight-600">Old Password</label>
                           <input type="password" class="form-control input-sm user-profile-validation"
                              formControlName="old_pass" placeholder="Enter the current password">
                        </div>
                        <div class="text-danger         text-left       mb-1 errorcontrols">
                           <div><small>Old Password is
                                 required.</small>
                           </div>
                        </div>
                     </div>
                     <div class="col-md-12">
                        <div class="form-group">
                           <label class="control-label font-weight-600">New Password</label>
                           <input type="password" class="form-control input-sm user-profile-validation"
                              formControlName="new_pass" placeholder="Enter the new password">
                        </div>
                        <div class="text-danger text-left mb-1 errorcontrols">
                           <div><small>New Password is
                                 required.</small>
                           </div>
                           <!-- <div *ngIf="f.new_pass.errors.pattern"><small>Minimum 8 character password.</small></div>
                           <div *ngIf="f.new_pass.errors.pattern"><small>Use uppercase letters(e.g. A-Z)</small></div>
                           <div *ngIf="f.new_pass.errors.pattern"><small>Use lowecase letters(e.g. a-z)</small></div>
                           <div *ngIf="f.new_pass.errors.pattern"><small>Use a number(e.g 123)</small></div> -->
                        </div>
                     </div>
                     <div class="col-md-12">
                        <div class="form-group">
                           <label class="control-label font-weight-600">Confirm new Password </label>
                           <input type="password" class="form-control input-sm user-profile-validation"
                              formControlName="c_pass" placeholder="Enter the confirm new password">
                        </div>
                        <div class="text-danger text-left mb-1 errorcontrols">
                           <div><small>Confirm Password is required.</small>
                           </div>
                        </div>
                        <div class="text-danger text-left mb-1 errorcontrols">
                           <div><small>New Password and Confirm Password is not match.</small>
                           </div>
                        </div>
                     </div>
                     <div class="col-md-12">
                        <button type="submit" class="btn btn-3d m-b-xs w-xs btn-sm btn-success user-profile-submit"
                           value="submit">
                           Save</button> &nbsp;
                        <button type="button" class="btn btn-3d m-b-xs  btn-sm btn-dark"
                           data-dismiss="modal">Cancel</button>
                     </div>
                  </div>
               </form>
            </div>
         </div>
      </div>
   </div>