export const environment = {
  production: true,
  serverUrl: 'https://bioveda-staging.evalue8.info/',
  download: 'https://bioveda-staging.evalue8.info/uploads/'
  // serverUrl: "https://bioveda.evalue8.info/",
  // download: 'https://bioveda.evalue8.info/uploads/',
  // serverUrl: 'http://localhost:3000/',
  // download: 'http://localhost:3000/uploads/',
  // serverUrl: 'https://bioveda-sa.evalue8.info/',
  // download: 'https://bioveda-sa.evalue8.info/uploads/',
  // download: 'https://bioveda.s3.ap-south-1.amazonaws.com/'
};
