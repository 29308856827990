import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';

const routes: Routes = [
  {path:'',
loadChildren:()=>import('./auth/auth.module').then(m=>m.AuthModule)},
{path:'dashboard',component:DashboardComponent,
loadChildren:()=>import('./dashboard/dashboard.module').then(m=>m.DashboardModule)}
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
