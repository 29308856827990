import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.css']
})
export class SideNavComponent implements OnInit {
  username: any;
  email: any;
  constructor(private router: Router) { }
  logout() {
    localStorage.removeItem('name');
    localStorage.removeItem('category');
    localStorage.removeItem('user');
    localStorage.removeItem('userdata');
    this.router.navigate(['/']);
  }
  ngOnInit(): void {
    this.username = localStorage.getItem('name') ? localStorage.getItem('name') : this.router.navigate(['/'])
    this.email = localStorage.getItem('email');

  }
}
// {"_id":"643e6ec6b388605c0eeabe4e","category":"super admin","createdAt":"2022-08-25T04:32:24.764Z","email":"admin@admin.com","kmCode":"KM00020","mobile":"8400873851","name":"Admin","p_password":"123456","password":"$2a$10$95r2arcAcuRoqB4wGtjit.S.CyG1LXunPqnt.8md4EuIto0VrDmfK","updatedAt":"2023-03-31T12:15:49.902Z"}
